<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="90"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
    </GlobalForm>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import _api from "@/utils/request";
export default {
  name: "Form",
  data() {
    return {
      initData: null,
      formItemList: [
        {
          key: "merchantName",
          type: "input",
          labelName: "商家名称:",
          placeholder: "请输入商家名称查询",
        },
        {
          key: "contactPhone",
          type: "input",
          labelName: "联系电话:",
          placeholder: "请输入联系电话查询",
        },
      ],
    };
  },
  created() {
    this.handleConfirm();
  },
  methods: {
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      let SeachParams = {};
      if (data) {
        SeachParams = {
          contactPhone: data.contactPhone,
          merchantName: data.merchantName,
          pageNum: 1,
          pageSize: 10,
        };
      }
      SeachParams. disuseLoding= () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
}
</style>
