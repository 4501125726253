<template>
  <div class="GlobalTable">
    <div class="button_top">
      <div class="faultBtn">
          <div :class="this.balanceStatus=== '00'? 'active' : 'none'" @click="btnClick('00')">
            全部({{warnNumber.allCount || 0}})
          </div>
          <div :class="this.balanceStatus=== '01'? 'active' : 'none'" @click="btnClick('01')">
            余额不足<span :class="this.balanceStatus !== '01' && warnNumber.lackCount > 0?'tipColor':''">({{warnNumber.lackCount}})</span>
          </div>
          <div :class="this.balanceStatus=== '02'? 'active' : 'none'" @click="btnClick('02')">
            余额充足({{warnNumber.fullCount || 0}})
          </div>
      </div>
    </div>
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column
        label="详细地址"
        width="130rpx"
        slot="merchantAddress"
        align="center"
      >
        <template slot-scope="{ row }">
        <el-tooltip
          v-if="row.merchantAddress"
          class="item"
          effect="dark"
          :content="row.merchantAddress"
          placement="top"
        >
          <div class="Remarks">
          {{ row.merchantAddress}}
          </div>
        </el-tooltip>
        <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="当前余额" slot="balance" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.balance">￥{{row.balance}}</span>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column label="预警阀值" slot="warningValue" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.warningValue">￥{{row.warningValue}}</span>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column label="余额状态" slot="balanceStatus" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.balanceStatus != '03'" :style="row.balanceStatus == '01'?'color:#FF687B' : 'color:#74C259'">{{row.balanceStatus == '01'?'余额不足':'余额充足'}}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="线上支付合作商" slot="onlinePayCompanyCount" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.onlinePayCompanyCount" style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;" @click="seeDialogTable(row,'merchant')">{{row.onlinePayCompanyCount}}</span>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column label="历史充值次数" slot="payCount" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.payCount" style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;" @click="seeDialogTable(row,'pay')">{{row.payCount}}</span>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column label="提醒次数" slot="sendCount" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.sendCount" style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;" @click="seeDialogTable(row,'send')">{{row.sendCount}}</span>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column
        label="备注说明"
        width="130rpx"
        slot="masterNote"
        align="center"
      >
        <template slot-scope="{ row }">
        <el-tooltip
          v-if="row.masterNote"
          class="item"
          effect="dark"
          :content="row.masterNote"
          placement="top"
        >
          <div class="Remarks">
            {{ row.masterNote}}
          </div>
        </el-tooltip>
        <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column width="240" label="凭证" slot="operation" align="center">
          <template slot-scope="{ row }">
              <el-button size="mini" style="margin-bottom:5px" :type="row.warningValue?'warning':'danger'" round @click="handleBtn(row)">{{row.warningValue?'修改预警值':'设置预警值'}}</el-button>
              <el-button size="mini" type="success" round @click="handleBtn(row,'remark')">{{row.masterNote?'修改备注':'添加备注'}}</el-button>
          </template>
      </el-table-column>
    </GlobalTable>
    <!-- 备注弹出框 -->
    <el-dialog
      class="remarks_dialog"
      :title='RemarksTitle'
      :visible.sync="RemarksShow"
      :close-on-click-modal="false"
      width="410px"
      @closed="remarksClosed"
    >
      <div style="margin-bottom: 10px">备注内容</div>
      <el-input
        maxlength="100"
        type="textarea"
        :rows="4"
        placeholder="请输入备注内容,最多100字"
        show-word-limit
        v-model="masterNote"
      >
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="remarksClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="remarksSure"
          >确认添加</el-button
        >
      </span>
    </el-dialog>
    <!--弹框-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :width="dialogWidth"
      @closed="handleClose"
    >
      <div style="color:#FF687B; margin-bottom: 10px">
        <span>说明: 当回收商家账户余额等于或小于该预警值时，系统自动生成一条提醒记录，并向回收商发送一条微信提醒</span>
      </div>
      <div class="warning">
        <el-input
          v-model="warningValue"
          placeholder="请输入预警值"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="warnClose">取消</el-button>
        <el-button type="primary" @click.native="warnSure" :loading="butLoading">确定</el-button>
      </span>
    </el-dialog>
    <DialogTbale
      :show="tableVisible"
      :loading="dialogloading"
      :title="dialogTbaleTitle"
      :columns="DialogTableColumns"
      :data="DialogDataList"
      :currentPage="dialogPage.pageNum"
      :total="dialogPage.total"
      :pageSize="dialogPage.pageSize"
      :width="800"
      @onClose="onClose"
      @handleCurrentChange="handleCurrentChangeDialog"
    >
      <el-table-column label="地址" slot="companyAddress" align="center">
        <template slot-scope="{ row }">
          <div>
            <el-tooltip
              v-if="row.companyAddress"
              class="item"
              effect="dark"
              :content="row.companyAddress"
              placement="top"
            >
              <div class="Remarks">
                {{ row.companyAddress}}
              </div>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="合作状态" slot="companyIsEnable" align="center">
        <template slot-scope="{ row }">
          <span>{{row.companyIsEnable?'合作中':'取消合作'}}</span>
        </template>
      </el-table-column>
    </DialogTbale>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Table",
  data() {
    return {
      loading: false,
      seachDataList: [],
      balanceStatus: '00',
      masterNote: "",
      warningValue: "",
      butLoading: false,
      merchantId: "",
      warnNumber: {},
      page:{
        pageNum:1,
        pageSize:10
      },
      dialogPage: {
        pageNum:1,
        pageSize:10,
        total: 0,
      },
      pageNum:1,
      dialogTbaleType: "",
      //弹窗
      tableVisible: false,
      dialogloading: false,
      dialogTbaleTitle: "",
      RemarksTitle: "",
      RemarksShow: false,
      dialogVisible: false, // 弹框的出现与否
      dialogTitle: "", // 标题
      dialogWidth: '600px',
      tableColumns: [
        { label: "回收商名称", prop: "merchantName" },
        { slotName: "merchantAddress" },
        { label: "负责人", prop: "contactName" },
        { label: "联系电话", prop: "contactPhone" },
        { slotName: "balance" },
        { slotName: "warningValue" },
        { slotName: "balanceStatus" },
        { slotName: "onlinePayCompanyCount" },
        { slotName: "payCount" },
        { label: "最近充值时间", prop: "lastPayTime" },
        { slotName: "sendCount" },
        { slotName: "masterNote" },
        { slotName: "operation" }
      ],
      DialogDataList: [],
      DialogTableColumns: [
        { label: "门店商名称", prop: "companyName" },
        { label: "联系人", prop: "contact" },
        { label: "联系电话", prop: "phone" },
        { label: "地址", prop: "companyAddress" },
        { slotName: "companyIsEnable" }
      ]
    };
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  created() {
    this.handleCurrentChange();
  },
watch:{
    SeachParams(newVal){
      console.log(newVal)
      this.page.pageNum = 1;
      this.handleCurrentChange()
    }
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      }
      const baseRequest = {
        balanceStatus: this.balanceStatus,
        contactPhone: this.SeachParams.contactPhone,
        merchantName: this.SeachParams.merchantName,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true
      _api.warnList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.page.records;
          this.seachDataList.forEach(item => {
            item.lastPayTime = item.lastPayTime || '--'
          })
          this.warnNumber.allCount = res.data.allCount
          this.warnNumber.lackCount = res.data.lackCount
          this.warnNumber.fullCount = res.data.fullCount
          this.page.total = res.data.page.total;
          this.page.pageNum = res.data.page.current;
          this.loading = false
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
            this.SeachParams.disuseLoding();
          }
      });
    },
    // 弹出备注弹框
    handleBtn(row,type){
      console.log(row);
      this.merchantId = row.merchantId
      if(type == 'remark'){
        if(row.masterNote){
            this.RemarksTitle = '修改备注'
        }else{
            this.RemarksTitle = '添加备注'
        }
        this.masterNote = row.masterNote
        this.RemarksShow = true
      }else{
        this.dialogWidth = '370px'
        if(row.warningValue){
          this.dialogTitle = '修改预警值-'+ row.merchantName
          this.warningValue = row.warningValue
        }else{
          this.dialogTitle = '设置预警值-'+ row.merchantName
        }
        this.dialogVisible = true
      }
    },
    // 关闭备注弹框
    remarksClosed() {
        this.RemarksShow = false
        this.masterNote = ""
    },
    warnClose() {
      this.dialogVisible = false
    },
    warnSure() {
      this.butLoading = true
      _api.setWarnValue({merchantId:this.merchantId,warningValue:this.warningValue}).then(res => {
        if(res.code === 1){
          this.$message.success(res.msg||'操作成功')
          this.dialogVisible = false
          this.handleCurrentChange()
          this.butLoading = false
        }
      }).catch(err => {
          this.butLoading = false
      })
    },
    // 备注确定提交
    remarksSure() {
        this.butLoading = true
        _api.warnMasterNote({merchantId:this.merchantId,balanceWarningNote:this.masterNote}).then(res => {
            if (res.code === 1) {
                this.$message.success(res.msg)
                this.RemarksShow = false
                this.handleCurrentChange()
            }
            this.butLoading = false
        }).catch(err => {
            this.butLoading = false
        })
    },
    // 上账/下账 点击
    handBtn(type) {
      this.$router.push({path:"/payment/setAccount",query:{type}})
    },
    btnClick(type){
      this.balanceStatus = type
      this.page.pageNum = 1
      this.handleCurrentChange()
    },
    // 表格操作
    seeDialogTable(row,type) {
      this.tableVisible = true
      this.merchantId = row.merchantId
      this.dialogTbaleType = type
      if(type == 'merchant'){
        this.dialogTbaleTitle = '查看线上支付合作商-'+ row.merchantName
      }else if(type == 'pay') {
        this.dialogTbaleTitle = '查看历史充值-'+ row.merchantName
      }else{
        this.dialogTbaleTitle = '查看提醒次数-'+ row.merchantName
      }
      this.handleCurrentChangeDialog()
    },
    handleClose() {
      this.warningValue = ''
      this.merchantId = ''
      this.dialogVisible = false
    },
    onClose() {
      this.tableVisible = false
      this.merchantId = ""
      this.dialogTbaleType = ""
      this.dialogPage = {
        pageNum:1,
        pageSize:10,
        total: 0,
      }
    },
    handleCurrentChangeDialog(val) {
      if (val) {
        this.dialogPage.pageNum = val;
      } else {
        this.dialogPage.pageNum = 1;
      }
      let baseRequest = {
        merchantId: this.merchantId,
        pageNum: this.dialogPage.pageNum,
        pageSize: this.dialogPage.pageSize,
      };
      this.dialogloading = true
      if(this.dialogTbaleType == 'merchant'){
        this.DialogTableColumns = [
          { label: "门店商名称", prop: "companyName" },
          { label: "联系人", prop: "contact" },
          { label: "联系电话", prop: "phone" },
          { slotName: "companyAddress" },
          { slotName: "companyIsEnable" }
        ]
        _api.onlinePayCompanyList(baseRequest).then((res) => {
          if (res.code === 1) {
            this.DialogDataList = res.data.records;
            this.dialogPage.pageNum = res.data.current;
            this.dialogPage.total = res.data.total;
            this.dialogloading = false
          }
        });
      }else if(this.dialogTbaleType == 'pay'){
        this.DialogTableColumns = [
          { label: "上账时间", prop: "createTime" },
          { label: "上账金额", prop: "amountPrice" },
          { label: "转款方式", prop: "payeeWay" },
          { label: "操作人", prop: "adminName" }
        ]
        baseRequest.opt = '00'
        _api.accountList(baseRequest).then((res) => {
          if (res.code === 1) {
            this.DialogDataList = res.data.list.records;
            this.DialogDataList.forEach(item => {
              item.amountPrice = '￥'+ item.amountPrice
            })
            this.dialogPage.pageNum = res.data.list.current;
            this.dialogPage.total = res.data.list.total;
            this.dialogloading = false
          }
        });
      }else{
        this.DialogTableColumns = [
          { label: "提醒时间", prop: "createTime" },
          { label: "提醒时余额", prop: "balance" },
          { label: "预警阀值", prop: "warningValue" }
        ]
        _api.getSendWarnLog(baseRequest).then((res) => {
          if (res.code === 1) {
            this.DialogDataList = res.data.records;
            this.DialogDataList.forEach(item => {
              item.balance = '￥'+ item.balance
              item.warningValue = '￥'+ item.warningValue
            })
            this.dialogPage.pageNum = res.data.current;
            this.dialogPage.total = res.data.total;
            this.dialogloading = false
          }
        });
      }

    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .button_top{
    margin-bottom: 20px;;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .faultBtn{
    width: 432px;
    height: 34px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;
    .active{
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981FF;
      border-radius: 20px;
    }
    .none{
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }
    // 备注说明
    .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
    }
  .remarks-tip{
    color: #0981FF;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
    margin-bottom: 10px;
  }
  .tipColor{
    color: #FF687B;
  }
}
</style>
